<template>
  <div>
    <div class="d-flex">
      <div style="max-width: 55%" v-html="section.section_description"></div>
      <div>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              @click="editSection(iSkill, iSection, skill.id, section.id)"
              right
              >mdi-file-document-edit-outline</v-icon
            >
          </template>
          <span>Cập nhật Section</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              @click="
                deleteSection(
                  iSkill,
                  iSection,
                  section.id,
                  section.id_section_test_mapping
                )
              "
              right
              >mdi-delete</v-icon
            >
          </template>
          <span>Xóa Section</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click="openDialogSortSection(iSkill, iSection)"
              v-bind="attrs"
              v-on="on"
              right
              >mdi-sort</v-icon
            >
          </template>
          <span>Sắp xếp thứ tự section</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click="duplicateSection(iSkill, iSection)"
              v-bind="attrs"
              v-on="on"
              right
              >mdi-content-copy</v-icon
            >
          </template>
          <span>Duplicate section</span>
        </v-tooltip>
      </div>
    </div>

    <div>
      <v-btn
        :style="{ display: !scrollBtnSection ? 'none' : 'block' }"
        :class="{ 'scroll-btn-section': scrollBtnSection }"
        @click="openDialogSection(iSkill, skill.id)"
        class="ma-2 font-weight-bold"
        color="#1479f4"
      >
        <v-icon>mdi-plus</v-icon> Thêm Section
      </v-btn>
      <v-btn
        color="cyan"
        v-if="section.element_type == null && section.element_data == null"
        class="ma-2 font-weight-bold"
        outlined
        large
        @click="openDialogElement(iSkill, iSection, section.id, 'element')"
      >
        <v-icon left>mdi-file-image</v-icon> Tạo Element (Section)
      </v-btn>

      <v-btn
        v-if="section.element_type != null && section.element_data != null"
        class="ma-2 font-weight-bold"
        outlined
        large
        color="teal"
        @click="editElement(iSkill, iSection, section.id, 'element')"
      >
        <v-icon left>mdi-pencil</v-icon> Cập nhật Element (Section)
      </v-btn>
      <v-btn
        outlined
        large
        class="font-weight-bold"
        color="red"
        @click="deleteElement(iSkill, iSection)"
        v-if="section.element_type != null && section.element_data != null"
      >
        <v-icon left>mdi-delete</v-icon> Xóa Element (Section)
      </v-btn>

      <v-btn
        v-if="
          section.sub_element_type == null && section.sub_element_data == null
        "
        @click="
          openDialogSubElement(
            iSkill,
            iSection,
            null,
            section.id,
            null,
            'section'
          )
        "
        outlined
        large
        class="font-weight-bold ma-2"
        color="deep-purple"
      >
        <v-icon left>mdi-plus</v-icon> Bổ sung sub element
      </v-btn>
      <v-btn
        v-if="
          section.sub_element_type != null && section.sub_element_data != null
        "
        @click="
          editSubElement(iSkill, iSection, null, section.id, null, 'section')
        "
        outlined
        large
        class="font-weight-bold ma-2"
        color="deep-purple"
      >
        <v-icon left>mdi-pencil</v-icon> Cập nhật sub element
      </v-btn>
      <v-btn
        outlined
        large
        class="font-weight-bold"
        color="red"
        @click="deleteSubElement(iSkill, iSection)"
        v-if="
          section.sub_element_type != null && section.sub_element_data != null
        "
      >
        <v-icon left>mdi-delete</v-icon> Xóa sub element (Section)
      </v-btn>
    </div>
  </div>
</template>

<script>
import ApiService from "@/service/api.service";
import Swal from "sweetalert2";
import {mapGetters, mapState} from "vuex";
import constantTestBuilder from "../../mixins/Support/constantTestBuilder";

export default {
  name: "SectionAction",
  props: {
    skill: {
      type: Object,
      default() {
        return {};
      },
    },
    section: {
      type: Object,
      default() {
        return {};
      },
    },
    iSkill: {
      type: Number||String,
      default: 0,
    },
    iSection: {
      type: Number||String,
      default: 0,
    },
    scrollBtnSection: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [
      constantTestBuilder,
  ],
  data() {
    return {};
  },
  computed: {
    ...mapState({
      product_category_id: (state) => state.informationTestBuilderStore.product_category_id,
      type_expand: (state) => state.informationTestBuilderStore.type_expand,
    }),
    ...mapGetters({
      skills: "contentTestBuilderStore/skills",
    }),
    is_call_api: {
      get() {
        return this.$store.state.informationTestBuilderStore.is_call_api;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setIsCallApi", value);
      },
    },
    dialogSection: {
      get() {
        return this.$store.state.contentTestBuilderStore.dialogSection;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setDialogSection", value);
      },
    },
    skill_id: {
      get() {
        return this.$store.state.contentTestBuilderStore.skill_id;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setSkillId", value);
      },
    },
    section_id: {
      get() {
        return this.$store.state.contentTestBuilderStore.section_id;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setSectionId", value);
      },
    },
    exercise_id: {
      get() {
        return this.$store.state.contentTestBuilderStore.exercise_id;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setExerciseId", value);
      },
    },
    type_form_section: {
      get() {
        return this.$store.state.contentTestBuilderStore.type_form_section;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setTypeFormSection", value);
      },
    },
    indexSkill: {
      get() {
        return this.$store.state.contentTestBuilderStore.indexSkill;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setIndexSkill", value);
      },
    },
    indexSection: {
      get() {
        return this.$store.state.contentTestBuilderStore.indexSection;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setIndexSection", value);
      },
    },
    indexExercise: {
      get() {
        return this.$store.state.contentTestBuilderStore.indexExercise;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setIndexExercise", value);
      },
    },
    dialogSortSection: {
      get() {
        return this.$store.state.contentTestBuilderStore.dialogSortSection;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setDialogSortSection",
          value
        );
      },
    },
    dialogElement: {
      get() {
        return this.$store.state.contentTestBuilderStore.dialogElement;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setDialogElement", value);
      },
    },
    element_form_display: {
      get() {
        return this.$store.state.contentTestBuilderStore.element_form_display;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setElementFormDisplay",
          value
        );
      },
    },
    type_form_element: {
      get() {
        return this.$store.state.contentTestBuilderStore.type_form_element;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setTypeFormElement", value);
      },
    },
    dialogSubElement: {
      get() {
        return this.$store.state.contentTestBuilderStore.dialogSubElement;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setDialogSubElement",
          value
        );
      },
    },
    model_sub_element: {
      get() {
        return this.$store.state.contentTestBuilderStore.model_sub_element;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setModelSubElement", value);
      },
    },
  },
  // watch: {
  //   dialogSection(value) {
  //
  //   },
  // },
  methods: {
    openDialogSection(index, skill_id) {
      this.type_form_section = "add";
      this.skill_id = skill_id;
      this.indexSkill = index;
      this.dialogSection = true;
    },
    editSection(indexSkill, indexSection, skill_id, section_id) {
      this.dialogSection = true;
      this.type_form_section = "update";
      this.indexSkill = indexSkill;
      this.indexSection = indexSection;
      this.skill_id = skill_id;
      this.section_id = section_id;
    },
    deleteSection(
      indexSkill,
      indexSection,
      section_id,
      section_test_mapping_id
    ) {
      if (this.product_category_id === this.product_category_constant.PREPTALK_ENGLISH) {
        if (this.type_expand === this.test_type_expand_constant.LESSON) {
          this.errorMess('Không được xóa section trong kỳ thi Preptalk English');
          return;
        }
      }
      let vm = this;
      Swal.fire({
        title: vm.getDeleteSectionMessage(indexSkill),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Xác nhận",
      }).then((result) => {
        if (result.value) {
          vm.is_call_api = true;
          let data = {
            section_id: section_id,
            section_test_mapping_id: section_test_mapping_id,
          };
          ApiService.post("prep-app/test/draft/section/delete", data)
            .then(function (res) {
              if (res.status == 200) {
                for (
                  let iSec = vm.skills[indexSkill].sections.length - 1;
                  iSec >= indexSection + 1;
                  iSec--
                ) {
                  vm.skills[indexSkill].sections[iSec].offset_question -=
                    vm.skills[indexSkill].sections[
                      indexSection
                    ].total_question;
                }
                vm.skills[indexSkill].currentTabSection -= 1;
                vm.skills[indexSkill].totalSection -= 1;
                vm.skills[indexSkill].sections.splice(indexSection, 1);
                vm.$set(vm.skills);
                vm.is_call_api = false;
              }
            })
            .catch(function (error) {
              if (error) {
                vm.is_call_api = false;
                console.log(error.response.data)
                if ([422].includes(error.response.status) ) {
                  vm.errorMess(error.response.data.error.message);
                } else {
                  vm.errorMess('Có lỗi xảy ra. Vui lòng liên hệ bộ phận kỹ thuật hỗ trợ');
                }
              }
            });
        }
      });
    },
    getDeleteSectionMessage(indexSkill) {
      let txt = "Bạn có chắc chắc muốn xóa thông tin này?";
      if (this.product_category_id === this.product_category_constant.IELTS) {
        if (this.skills[indexSkill].vsr == 1) {
          txt = "Đây là section được tạo mặc định cho kỹ năng Speaking của VSR IELTS, bạn chắc chắn muốn xoá?";
        }
      }
      return txt;
    },
    openDialogSortSection(indexSkill) {
      let vm = this;
      if (this.product_category_id === this.product_category_constant.IELTS) {
        if (isVSR()) {
          vm.errorMess('Không được sắp xếp các section trong kỳ thi IELTS có cấu hình vsr');
          return;
        }
      }
      if (vm.product_category_id === vm.product_category_constant.PREPTALK_ENGLISH) {
        if (vm.type_expand === vm.test_type_expand_constant.LESSON){
          vm.errorMess('Không được sắp xếp các section trong kỳ thi Preptalk English');
          return;
        }
      }
      if (vm.product_category_id === vm.product_category_constant.HSK) {
        if (isVSR()) {
          vm.errorMess('Không được sắp xếp các section trong kỳ thi HSK có cấu hình vsr');
          return;
        }
      }
      vm.indexSkill = indexSkill;
      vm.dialogSortSection = true;

      function isVSR() {
        console.log(vm.skills[indexSkill]);
        return vm.skills[indexSkill].vsr === 1;
      }
    },
    duplicateSection(indexSkill, indexSection) {
      console.log(this.skills)
      if (this.product_category_id === this.product_category_constant.IELTS) {
        if (this.skills[indexSkill].vsr == 1) {
          this.errorMess('Không được duplicate section trong kỳ thi IELTS có cấu hình vsr');
          return;
        }
      }
      if (this.product_category_id === this.product_category_constant.PREPTALK_ENGLISH) {
        this.errorMess('Không được duplicate section trong kỳ thi Preptalk English');
        return;
      }
      let vm = this;
      this.indexSkill = indexSkill;
      this.indexSection = indexSection;
      Swal.fire({
        title: "Bạn có chắc chắc muốn duplicate section này?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Xác nhận",
      }).then((result) => {
        if (result.value) {
          let data = {
            section_id: vm.skills[indexSkill].sections[indexSection].id,
            offset: vm.skills[indexSkill].sections.length + 1,
          };
          vm.is_call_api = true;
          ApiService.post("prep-app/test/draft/section/duplicate", data)
            .then(function (res) {
              if (res.status == 200) {
                let section = vm.convertDataSection(
                  res.data,
                  vm.skills[indexSkill].sections
                );
                vm.skills[indexSkill].sections.push(section);
                vm.$set(vm.skills);
                vm.is_call_api = false;
              }
            })
            .catch(function (error) {
              console.log(error);
              if (error) {
                vm.is_call_api = false;
                vm.errorMess("Thao tác không thành công. Vui lòng liên hệ phòng kỹ thuật hỗ trợ")
              }
            });
        }
      });
    },
    convertDataSection(section, sections) {
      section.exercises = this.convertDataExercise(section.exercises);
      section.total_question = this.calculatorTotalQuesOfSec(section);
      section.offset_question = this.recursiveOffsetQuesOfSec(
        sections,
        sections.length
      );
      return section;
    },
    convertDataExercise(exercises) {
      for (let iE = 0; iE < exercises.length; iE++) {
        exercises[iE].exercise_type = exercises[iE].type;
        exercises[iE].is_element = false;
        exercises[iE].total_curly_braces = this.countCurlyBraces(
          exercises[iE].body,
          exercises[iE].type
        );
        exercises[iE].body = this.convertBodyExercise(
          exercises[iE].body,
          exercises[iE].type
        );
        exercises[iE].total_question = this.calculatorTotalQuesOfExer(
          exercises[iE]
        );
        exercises[iE].offset_question = this.recursiveOffsetQuesOfExer(
          exercises,
          iE
        );
        exercises[iE].questions = this.convertDataQuestion(
          exercises[iE].questions,
          exercises[iE].exercise_type
        );
      }
      return exercises;
    },
    calculatorTotalQuesOfSec(section) {
      let total = 0;
      for (let iExer = 0; iExer < section.exercises.length; iExer++) {
        total += section.exercises[iExer].total_question;
      }
      return total;
    },
    recursiveOffsetQuesOfSec(sections, index) {
      let offset = 0;
      if (index == 0) {
        return 0;
      }
      offset = sections[index - 1].total_question;
      return offset + this.recursiveOffsetQuesOfSec(sections, index - 1);
    },
    recursiveOffsetQuesOfExer(exercises, index) {
      let offset = 0;
      if (index == 0) {
        return 0;
      }
      offset = exercises[index - 1].total_question;
      return offset + this.recursiveOffsetQuesOfExer(exercises, index - 1);
    },
    countCurlyBraces(body, type) {
      let total = 0;
      if (type == this.exercise_type_constant.SHORT_ANSWER) {
        total = (body.match(/{{(.*?)}}/g) || []).length;
      }
      return total;
    },
    calculatorTotalQuesOfExer(exercise) {
      let total = 0;
      if (exercise.exercise_type == this.exercise_type_constant.SHORT_ANSWER) {
        total += exercise.total_curly_braces;
      } else if (exercise.exercise_type == this.exercise_type_constant.MATCHING) {
        total += exercise.body.correct.length;
      } else if (exercise.exercise_type == this.exercise_type_constant.ANSWER_IN_ANY_ORDER) {
        total += exercise.body.length;
      } else {
        total += exercise.questions.length;
      }
      return total;
    },
    convertBodyExercise(body, type) {
      if (type == this.exercise_type_constant.MATCHING) {
        body = JSON.parse(body);
      } else if (type == this.exercise_type_constant.ANSWER_IN_ANY_ORDER) {
        body = JSON.parse(body);
      }
      return body;
    },
    convertDataQuestion(questions, exercise_type) {
      for (let iQ = 0; iQ < questions.length; iQ++) {
        questions[iQ].description = questions[iQ].sample_content;
        questions[iQ].answer_id =
          questions[iQ].answers.length > 0 ? questions[iQ].answers[0].id : null;
        questions[iQ].body = this.convertBodyQuestion(
          questions[iQ].body,
          exercise_type
        );
        delete questions[iQ].content;
        delete questions[iQ].sample_content;
      }
      return questions;
    },
    convertBodyQuestion(body, type) {
      if (type == this.exercise_type_constant.SENTENCE_STRESSES) {
        body = JSON.parse(body);
      }
      return body;
    },
    openDialogElement(
      indexSkill = null,
      indexSection = null,
      section_id = null,
      element_form_display = null
    ) {
      this.indexSkill = indexSkill;
      this.indexSection = indexSection;
      this.section_id = section_id;
      this.element_form_display = element_form_display;
      this.dialogElement = true;
      this.type_form_element = "add";
    },
    editElement(
      indexSkill = null,
      indexSection = null,
      section_id = null,
      element_form_display = null
    ) {
      this.indexSkill = indexSkill;
      this.indexSection = indexSection;
      this.section_id = section_id;
      this.element_form_display = element_form_display;
      this.dialogElement = true;
      this.type_form_element = "edit";
    },
    deleteElement(indexSkill, indexSection) {
      let vm = this;
      let data = {
        section_id: vm.skills[indexSkill].sections[indexSection].id,
        type_delete: "section",
      };
      Swal.fire({
        title: "Bạn có chắc chắc muốn xóa thông tin này?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Xác nhận",
      }).then((result) => {
        if (result.value) {
          vm.is_call_api = true;
          ApiService.post("prep-app/test/draft/section/delete-element", data)
            .then(function (res) {
              if (res.status == 200) {
                self.is_call_api = true;
                vm.skills[indexSkill].sections[indexSection].element_data =
                  null;
                vm.skills[indexSkill].sections[indexSection].element_id = null;
                vm.skills[indexSkill].sections[indexSection].element_type =
                  null;
                vm.$set(vm.skills);
                vm.is_call_api = false;
              }
            })
            .catch(function (error) {
              if (error) {
                vm.is_call_api = false;
                vm.errorMess("Thao tác không thành công. Vui lòng liên hệ phòng kỹ thuật hỗ trợ",);
              }
            });
        }
      });
    },
    openDialogSubElement(
      indexSkill = null,
      indexSection = null,
      indexExercise = null,
      section_id = null,
      exercise_id = null,
      model_sub_element = null
    ) {
      this.indexSkill = indexSkill;
      this.indexSection = indexSection;
      this.indexExercise = indexExercise;
      this.model_sub_element = model_sub_element;
      this.section_id = section_id;
      this.exercise_id = exercise_id;
      this.type_form_element = "add";
      this.element_form_display = null;
      this.dialogSubElement = true;
    },
    editSubElement(
      indexSkill = null,
      indexSection = null,
      indexExercise = null,
      section_id = null,
      exercise_id = null,
      model_sub_element = null
    ) {
      this.indexSkill = indexSkill;
      this.indexSection = indexSection;
      this.indexExercise = indexExercise;
      this.model_sub_element = model_sub_element;
      this.section_id = section_id;
      this.exercise_id = exercise_id;
      this.type_form_element = "update";
      this.element_form_display = null;
      this.dialogSubElement = true;
    },
    deleteSubElement(indexSkill, indexSection) {
      let vm = this;
      let data = {
        section_id: vm.skills[indexSkill].sections[indexSection].id,
      };
      Swal.fire({
        title: "Bạn có chắc chắc muốn xóa thông tin này?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Xác nhận",
      }).then((result) => {
        if (result.value) {
          vm.is_call_api = true;
          ApiService.post(
            "prep-app/test/draft/section/delete-sub-element",
            data
          )
            .then(function (res) {
              if (res.status === 200) {
                self.is_call_api = true;
                vm.skills[indexSkill].sections[indexSection].sub_element_data =
                  null;
                vm.skills[indexSkill].sections[indexSection].sub_element_id =
                  null;
                vm.skills[indexSkill].sections[indexSection].sub_element_type =
                  null;
                vm.$set(vm.skills);
                vm.is_call_api = false;
              }
            })
            .catch(function (error) {
              if (error) {
                vm.is_call_api = false;
                vm.errorMess('Thao tác không thành công. Vui lòng liên hệ phòng kỹ thuật hỗ trợ');
              }
            });
        }
      });
    },
    errorMess(msg = '') {
      this.$toasted.error(msg, {theme: "toasted-primary", position: "top-right", duration: 4000,});
    },
  },
};
</script>

<style lang="css" scoped>
.scroll-btn-section {
  position: fixed;
  top: 1px;
  left: 6%;
  z-index: 1000;
  color: white;
}
</style>
